import type { TIndexResponse } from '~/features/Home/types'

export default class HomeApi {
  private readonly axios = axiosClient

  get(): Promise<TIndexResponse> {
    try {
      const headers: { [key in string]: string } = {}
      const rawHeaders = useRequestHeaders()

      headers['x-forwarded-for-nuxt'] = rawHeaders['x-forwarded-for-nuxt']

      return this.axios.$get('/main/', {
        headers,
      })
    } catch {
      return this.axios.$get('/main/')
    }
  }
}
